var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "GrantApplicationPlatformForm-wrapper" },
    [
      _c(
        "form-panel",
        {
          attrs: {
            submitText: _vm.submitText,
            submitUrl: _vm.submitUrl,
            form: _vm.form,
            submitBefore: _vm.submitBefore,
            submitSuccess: _vm.submitSuccessFunc,
          },
          scopedSlots: _vm._u([
            {
              key: "saveBeforeSlot",
              fn: function () {
                return [
                  _vm.showReturn
                    ? _c("v-button", {
                        attrs: { text: "返回" },
                        on: { click: _vm.returnClick },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: _vm.title } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择公司",
                        prop: "regionIds",
                        rules: [
                          {
                            required: true,
                            message: "请选择公司",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.form.regionIds,
                          textAs: "regionName",
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "regionIds", $event)
                          },
                          select: function ($event) {
                            _vm.showTenantMultiSelect = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "授权应用" } },
                    [
                      _c("chosen-list-panel", {
                        attrs: { list: _vm.form.subIds, textAs: "subName" },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "subIds", $event)
                          },
                          select: function ($event) {
                            _vm.showMultiSelect = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "公司列表",
          isShow: _vm.showTenantMultiSelect,
          searchUrl: _vm.getAllTenantListURL,
          headers: _vm.tenantHeaders,
          searchParams: _vm.tenantSearchParams,
          responseParams: _vm.tenantResponseParams,
          responseKey: _vm.tenantResponseKey,
          backFill: _vm.form.regionIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showTenantMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showTenantMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "regionIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "regionIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "公司" },
                  model: {
                    value: _vm.tenantSearchParams.regionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.tenantSearchParams, "regionName", $$v)
                    },
                    expression: "tenantSearchParams.regionName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        attrs: {
          title: "应用列表",
          isShow: _vm.showMultiSelect,
          searchUrl: _vm.getApplicationListURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.subIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "subIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "subIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "应用名称" },
                  model: {
                    value: _vm.searchParams.subName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subName", $$v)
                    },
                    expression: "searchParams.subName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类别", options: _vm.subHandleOps },
                  model: {
                    value: _vm.searchParams.subHandle,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subHandle", $$v)
                    },
                    expression: "searchParams.subHandle",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用类型", options: _vm.subTypeOps },
                  model: {
                    value: _vm.searchParams.subType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subType", $$v)
                    },
                    expression: "searchParams.subType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "应用描述" },
                  model: {
                    value: _vm.searchParams.subNote,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "subNote", $$v)
                    },
                    expression: "searchParams.subNote",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }