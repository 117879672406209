// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonList`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/exportPlatformSubjects`

// 获取租户可授权应用列表 multi-select
const getApplicationListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantableTenant`
// 获取租户已授权应用列表
const getGrantApplicationListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantedSimpleTenant`
// 授权租户应用
const grantApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/grantSubjectsToTenant`
// 批量授权租户应用
const batchGrantApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/batchGrantSubjectsToTenants`
//获取租户列表
const getAllTenantListURL = `${API_CONFIG.butlerBaseURL}region/getRegionBusinessRelationship`

// 新增
const createURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonAdd`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonUpdate`
// 查询详情
const queryURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonView`
// 选择对象
const selectObjecURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/selectObjectSelectTo`

// 产品类型
const getExternalProductTypeURL = `${API_CONFIG.shopBaseURL}subjectLightWeightConfig/subjectExternalConfigList`
// 轻量级应用
const lightWeightConfigURL = `${API_CONFIG.controlBaseURL}subjectLightWeightConfig/subjectLightWeightConfigUpdate`

export {
  getListURL,
  exportListURL,
  getApplicationListURL,
  getAllTenantListURL,
  getGrantApplicationListURL,
  grantApplicationURL,
  createURL,
  editURL,
  queryURL,
  selectObjecURL,
  getExternalProductTypeURL,
  lightWeightConfigURL,
  batchGrantApplicationURL
}
